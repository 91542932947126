<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="permitDates"
            v-model="searchParam.permitDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 허가서구분 -->
          <c-select
            type="search"
            codeGroupCd="SOP_PERMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="permitTypeCd"
            label="허가서구분"
            v-model="searchParam.permitTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 보충작업 -->
          <c-select
            type="search"
            codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="supplementWorkTypeCd"
            label="보충작업"
            v-model="searchParam.supplementWorkTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SWP_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="swpStepCd"
            label="진행단계"
            v-model="searchParam.swpStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:top-right>
          </template>
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.swpStepCd)]">
                    {{ props.row.swpStepName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus2(props.row.permitTypeCd)]">
                    {{ props.row.permitTypeName }}
                  </div>
                  <div :class="['text-grid-etc', getColorStatus3(props.row.approvalStatusCd)]" v-if="props.row.approvalStatusCd">
                    {{ props.row.approvalStatusName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.workSummary }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.permitDate }} | {{ getSupWorkName(props.row.supplementWorkTypeNames) }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskWorkPermit',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: null,
        sopConstructionId: '',
        constructionFlag: true,
      },
      grid: {
        columns: [
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:70%',
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            label: '진행단계',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일자',
            align: 'center',
            sortable: true,
            style: 'width:30%',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'SS00000001': // 작성중
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'SS00000005': // 승인완료
          cls = 'txt-box-grid text-positive-box';
          break;
        case 'SS00000010': // 연장
          cls = 'txt-box-grid text-accent-box';
          break;
        case 'SS00000015': // 취소
          cls = 'txt-box-grid text-red-box';
          break;
        case 'SS00000020': // 작업완료
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    getColorStatus2(cd) {
      var cls = '';
      switch(cd) {
        case 'SPT0000001': // 일반
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'SPT0000005': // 화기
          cls = 'txt-box-grid text-red-box';
          break;
      }
      return cls;
    },
    getColorStatus3(cd) {
      var cls = '';
      switch(cd) {
        case 'ASC0000001': // 결재중
          cls = 'txt-box-grid text-gray-box text-blue';
          break;
        case 'ASC9999999': // 결재완료
          cls = 'txt-box-grid text-gray-box text-red';
          break;
      }
      return cls;
    },
    getSupWorkName(_val) {
      if (_val === null) {
        return '';
      } else {
        return '보충작업('+_val+')';
      }
    }
    /* eslint-disable no-unused-vars */
    // linkClick(row, col, index) {
    //   // 작업허가서
    //   this.popupOptions.title = '작업허가서';
    //   this.popupOptions.param = {
    //     sopWorkPermitId: row ? row.sopWorkPermitId : '',
    //     permitTypeCd: row ? row.permitTypeCd : '',
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitDetail.vue'}`);
    //   this.popupOptions.isFull = true;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup() {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   this.getList();
    // },
    // deleteTask() {
    //   let selectData = this.$refs['tasktable'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '제외할 행을 지정하세요.', // 제외할 행을 지정하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: '확인',
    //       message: '제외하시겠습니까?',
    //       // TODO : 필요시 추가하세요.
    //       type: 'info', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.task.deleteUrl;
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(this.$_.map(selectData, (item) => {
    //             return {
    //               sopConstructionId: this.popupParam.sopConstructionId,
    //               sopWorkPermitId: item.sopWorkPermitId,
    //             }
    //           }))
    //         };
    //         this.$http.request((_result) => {
    //           window.getApp.$emit('APP_REQUEST_SUCCESS');
    //           this.$refs['tasktable'].$refs['compo-table'].clearSelection();
    //           this.getList();
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    // addTask() {
    //   this.popupOptions.title = '작업허가서 검색'; // 작업허가서 검색
    //   this.popupOptions.param = {
    //     type: 'multiple'
    //   };
    //   this.popupOptions.target = () => import(`${'@/pages/sop/swp/safeWorkPermitPop.vue'}`);
    //   this.popupOptions.width = '80%';
    //   this.popupOptions.isFull = false;
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeTaskPopup;
    // },
    // closeTaskPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     let insertList = [];
    //     this.$_.forEach(data, item => {
    //       if (this.$_.findIndex(this.grid.data, { sopWorkPermitId: item.sopWorkPermitId }) === -1) {
    //         insertList.push({
    //           sopConstructionId: this.popupParam.sopConstructionId,
    //           sopWorkPermitId: item.sopWorkPermitId,
    //         });
    //       }
    //     })

    //     this.$http.url = this.task.insertUrl;
    //     this.$http.type = 'POST';
    //     this.$http.param = insertList
    //     this.$http.request(() => {
    //       window.getApp.$emit('APP_REQUEST_SUCCESS');
    //       this.getList();
    //     },);
    //   }
    // },
  }
};
</script>
